import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, lastValueFrom } from 'rxjs';
import { Dashboard } from 'src/app/components/models/dashboard/dashboard';
import { UserWallets } from 'src/app/components/models/user/user-wallets';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TradeupService {
  readonly httpResponseStringOpts: Object = {
    observe: 'response',
    responseType: 'text'
  };

  constructor(private httpClient: HttpClient) { }

  verifyWallet(wallet: string | null | undefined) {
    const url = `${environment.tradeUpUrl}/wallet/verify`;
    return this.httpClient.put(url, {id: wallet});
  }

  claimToken(eventName: string, formData: any) : Promise<HttpResponse<Object>> {
    const url = `${environment.tradeUpUrl}/user/event/${eventName}/claim/token`;
    return lastValueFrom(this.httpClient.post(url, formData, {observe: 'response', responseType: 'json'}));
  }

  getDashboard(eventId: string | undefined) : Promise<HttpResponse<Dashboard>> {
    const url = `${environment.tradeUpUrl}/dashboard?event=${eventId ?? 'unknown'}`;
    return lastValueFrom(this.httpClient.get<Dashboard>(url, {observe: 'response', responseType: 'json'}));
  }

  findClaim(eventId?: string) : Promise<HttpResponse<string>> {
    eventId = (typeof eventId !== 'undefined') ? eventId.trim().toLowerCase() : eventId;
    const url = `${environment.tradeUpUrl}/mint/action/${eventId}`;
    return lastValueFrom(this.httpClient.get(url, {observe: 'response', responseType: 'text'}));
  }

  getUserWallets() : Promise<HttpResponse<UserWallets>> {
    const url = `${environment.tradeUpUrl}/user/wallets`;
    return lastValueFrom(this.httpClient.get(url, {observe: 'response', responseType: 'json'}));
  }
}
