import { Component, OnInit } from '@angular/core';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';
import { Dashboard } from '../../models/dashboard/dashboard';

@Component({
  selector: 'app-has-claimed',
  templateUrl: './has-claimed.component.html',
  styleUrls: ['./has-claimed.component.scss']
})
export class HasClaimedComponent implements OnInit {
  public dashboard?: Dashboard;
  public isMobileUA: boolean = false;

  constructor(
    private dataSession: DataSessionService,
    private tradeUpService: TradeupService
  ) { }

  async ngOnInit(): Promise<void> {
    this.dashboard = this.dataSession.dashboardGet();
    this.isMobileUA = this.dataSession.isMobileGet();
    
    //get wallets if needed
    if (typeof this.dataSession.walletGet() === 'undefined') {
      try {
        const userWallets = await this.tradeUpService.getUserWallets();
        if (userWallets.body && userWallets.body.address) {
          if (userWallets.body.address.length > 0) {
            this.dataSession.walletSet(userWallets.body.address[userWallets.body.address.length - 1]);
          }
        }
      } catch (ex) { } // ignore catch
    }
  }

}
