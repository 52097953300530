<div id="image-col">
    <img src="{{dashboard?.token?.image}}" alt="Token image here" class="event_lead"/>
    <div class="infobox">
        <p>The event works!</p>
        <p>Info:</p>
        Event Name: {{dashboard?.event?.name}}<br>
        Event Start: {{dashboard?.event?.start}}<br>
        Event End: {{dashboard?.event?.end}}<br>
        Token Name: {{dashboard?.token?.name}}<br>
        Token tier: {{dashboard?.token?.tier}}<br>
    </div>
</div>
