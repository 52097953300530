import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';

declare let window: any;

@Component({
  selector: 'app-install-metamask',
  templateUrl: './install-metamask.component.html',
  styleUrls: ['./install-metamask.component.scss']
})
export class InstallmetamaskComponent implements OnInit {
  public isMobileUA: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataSessionService: DataSessionService
  ) { }

  ngOnInit(): void {
  }

  onInstalledClick(): void {
    let dashboard = this.dataSessionService.dashboardGet();
    this.isMobileUA = this.dataSessionService.isMobileGet();
    if (typeof dashboard !== 'undefined') {
      window.location.href = window.location.origin + `/event/${dashboard.event?.uniqueId}`;
      //this.router.navigate(['event', dashboard.event?.uniqueId]);
    } else {
      window.location.href = window.location.origin + '/event';
      //this.router.navigate(['event']);
    }
  }

}
