<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <div class="one-col-wrapper">
        <div class="wrapper-align">
            <h1 class="dramatic-header">There is no active event here at this time.</h1>
        </div>
        <div class="left-col-content center_align">
            <p *ngIf="!isLogged" class="information">Please create an account or login to be ready for the next event.</p>
            <p class="information">Join our discord and follow our twitter to find out when and where the next event will occur.</p>
            <div class="social_media center_align">
                <button *ngIf="!isLogged" class="create_account" (click)="login()">Create Account</button>
            </div>
            <div class="social_media center_align">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
    </div>
    <p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
</div>
<!-- ##Ended Desktop View## -->
<!-- ##Started Mobile View## -->
<div class="mobile-view">
    <div class="two-col-wrapper">
        <div class="right_align">
            <h1 class="dramatic-header">There is no active event here at this time.</h1>
        </div>
        <div class="left-col-content center_align">
            <p *ngIf="!isLogged" class="information">Please create an account or login to be ready for the next event.</p>
            <p class="information">Join our discord and follow our twitter to find out when and where the next event will occur.</p>
            <div class="social_media_mobile">
                <button *ngIf="!isLogged" class="create_account" (click)="login()">Create Account</button>
            </div>
            <div class="social_media_invalid">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
            </div>
            <div class="social_media_invalid">
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
    </div>
    <p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
</div>
<!-- ##Ended Mobile View## -->