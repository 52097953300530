import { KeycloakOptions, KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
    const options: KeycloakOptions = {
        config: {
            url: environment.keycloak.issuer,
            realm: environment.keycloak.realm,
            clientId: environment.keycloak.clientId
        },
        loadUserProfileAtStartUp: true,
        initOptions : {
            onLoad: 'check-sso',
            checkLoginIframe: false
        },
        // TODO: better define our public/private(auth-guard) urls
        bearerExcludedUrls: ['unft_tradeup/public']
    }
    return () => keycloak.init(options);
}