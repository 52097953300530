<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <div class="event-title">{{dashboard?.event?.name}}</div>
    <div class="two-col-wrapper">
        <div class="left-col-content">
            <div class="dramatic-header">Claim Your Unioverse Collectible</div>
            <div class="information">Get this Unioverse Collectible NFT for free. This numbered collectible will only be
                available for a limited time and never again.</div>
            <app-count-down></app-count-down>
            <button class="button" (click)="verifyAndClaim()">Claim</button>
            <div class="content-wrap">
                <div>
                    <div class="highlight_button">
                        <span><img src="assets/images/unioverse-icon-transparent.svg" alt="Unioverse"
                                class="login_alignment"><br />Log In</span>
                    </div>
                </div>
                <span class="before">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                <div>
                    <div class="highlight_button">
                        <i class="pi pi-wallet icon1"></i>
                        <span class="icon_content">Collect</span>

                    </div>
                </div>
                <span class="before">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                <div>
                    <div class="highlight_button">
                        <i class="pi pi-check-circle icon1"></i>
                        <span class="icon_content">Claim</span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-dashboard-display></app-dashboard-display>
        </div>
    </div>
    <p-dialog styleClass="addModal" [(visible)]="displayModal" [style]="{width: '50vw'}">
        <p class="blacklist">{{modalTitle}}<br />{{modalBody}}</p>
        <ng-template pTemplate="footer">
            <div class="dialog-ok">
                <p-button class="buttonpop" (click)="modalDismissed()" label="Ok" styleClass="p-button-text">
                </p-button>
            </div>
        </ng-template>
    </p-dialog>
    <p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
</div>
<!-- ##Ended Desktop View## -->
<!-- ##Started Mobile View## -->
<div class="mobile-view">
    <div class="event-title">{{dashboard?.event?.name}}</div>
    <div class="two-col-wrapper" *ngIf="isNoAlert">
        <div class="left-col-content">

            <div class="dramatic-header">Unioverse Collectibles</div>
            <div>
                <app-count-down></app-count-down>
            </div>
            <div>
                <app-dashboard-display></app-dashboard-display>
            </div>
            <button class="button" (click)="verifyAndClaim()">Claim</button>
            <div class="information">Get this Unioverse Collectible NFT for free. This numbered collectible will only be
                available for a limited time and never again.</div>
        </div>
    </div>
    <p-dialog styleClass="addModal_mobile" [(visible)]="displayModal" [style]="{width: '70vw'}">
        <p class="blacklist_mobile">{{modalTitle}}<br />{{modalBody}}</p>
        <ng-template pTemplate="footer">
            <div class="dialog-ok_mobile">
                <p-button class="buttonpop_mobile" (click)="modalMobileDismissed()" label="Ok" styleClass="p-button-text">
                </p-button>
            </div>
        </ng-template>
    </p-dialog>
    <div class="two-col-wrapper" *ngIf="isAlert">
        <div class="left-col-content">
            <div class="alert_border">
                <div><span><i class="pi pi-exclamation-triangle icon_alert"></i></span></div>
                <div class="alert_text"> Please log into a desktop version of the site and setup your
                    wallet there.
                </div>
            </div>
            <div class="dramatic-header">Unioverse Collectibles</div>
            <div>
                <app-count-down></app-count-down>
            </div>
            <div>
                <app-dashboard-display></app-dashboard-display>
            </div>
        </div>
    </div>
    <p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
</div>
<!-- ##Ended Mobile View## -->