import { Component, OnInit } from '@angular/core';
import { CountdownTimerService } from 'src/app/services/countdown-timer/countdown-timer.service';
import { Timer } from 'src/app/services/countdown-timer/models/timer';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent implements OnInit {
  public timer$?: Observable<Timer>;

  constructor(
    private countdownTimerService: CountdownTimerService
  ) { }

  ngOnInit(): void {
    // register for the countdown timer
    this.timer$ = this.countdownTimerService.getCountdown();
  }

}
