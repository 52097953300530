import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { StringUtilsService } from 'src/app/services/string-utils/string-utils.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TradeupWeb3Service } from 'src/app/services/tradeup-web3/tradeup-web3.service';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';
import { StringsEn } from '../../common/strings/strings-en';
import { Dashboard } from '../../models/dashboard/dashboard';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {
  public dashboard?: Dashboard;
  public displayModal: boolean = false;
  public selectedWallet?: string;
  public isWalletValid: boolean = false;
  public modalTitle: string = "";
  public modalBody: string = "";
  public modalCode: number = 0;
  public isAlert: boolean = false;
  public isNoAlert: boolean = true;
  public isMobileUA: boolean = false;
  public showSpinner: boolean = false;
  
  constructor(

    private primengConfig: PrimeNGConfig,
    private dataSession: DataSessionService,
    private tradeUpService: TradeupService,
    private router: Router,
    private route: ActivatedRoute,
    private stringUtilsService: StringUtilsService,
    private tradeupWeb3Service: TradeupWeb3Service,
    private toastService: ToastService
  ) { }
  async ngOnInit(): Promise<void> {
    this.primengConfig.ripple = true;
    this.dashboard = this.dataSession.dashboardGet();
    this.selectedWallet = this.dataSession.walletGet();
    this.isMobileUA = this.dataSession.isMobileGet();
    console.log('selected wallet: ' + this.selectedWallet);

    if (this.dashboard == null || this.dashboard == undefined ||
      this.selectedWallet == null || this.selectedWallet == undefined) {
      // go back to 'start'
      this.router.navigate(['../'], { relativeTo: this.route });
      return;
    }

    // check to see if user has already claimed the nft/event
    if (this.route.snapshot.data['hasClaimed']) {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
  async verifyAndClaim(): Promise<void> {
   
    const signature = this.tradeupWeb3Service.walletSignatureGet();
    // allowing to pass up wallet w/o signature, just won't save into keycloak
    //const wallet = (signature) ? this.selectedWallet : undefined;
    const wallet = this.selectedWallet;
    const walletClaimRequest = {
      wallet_id: wallet,
      signature,
      dryPost: false,
      token_id: this.dashboard?.token?.id
    };
    console.log(`Sending claim request: ${JSON.stringify(walletClaimRequest)}`);
    try {
      this.showSpinner = true;
      await this.tradeUpService.claimToken(this.stringUtilsService.unbox(this.dashboard?.event?.uniqueId), walletClaimRequest);
      // non-error -- errors are caught below
      this.toastService.displaySuccess(StringsEn.CLAIM_TOKEN.SUCCESS_TITLE, StringsEn.CLAIM_TOKEN.SUCCESS_BODY, 5000);
      this.router.navigate(['../claimed'], { relativeTo: this.route });
      this.showSpinner = false;
    } catch (err: any) {
      this.showSpinner = true;
      this.modalCode = err.status;
      switch (err.status) {
        case 403:
          // wallet verification failed
          this.modalTitle = "Oh no! We could not verify your wallet!";
          this.modalBody = "Please selecte a different wallet to sign up with and collect your free NFT!"
          this.showModal();
          //this.toastService.displayError(StringsEn.CLAIM_TOKEN.FAILED_VERIFICATION_TITLE, StringsEn.CLAIM_TOKEN.FAILED_OTHER_BODY, 5000);
          //this.router.navigate(['../changewallet'], { relativeTo: this.route });
          //this.displayModal = true;
          break;
        case 409:
          // already claimed -- same as success?
          this.modalTitle = "Don't be greedy!";
          this.modalBody = "You already claimed this NFT, stay tuned for the next event to get the next one!";
          this.showModal();
          //this.toastService.displaySuccess(StringsEn.CLAIM_TOKEN.SUCCESS_TITLE, StringsEn.CLAIM_TOKEN.SUCCESS_BODY, 5000);
          //this.router.navigate(['../claimed'], { relativeTo: this.route });
          //this.displayModal = true;
          break;
        case 410:
          // wallet not on allow list
          this.modalTitle = "Uh-oh.";
          this.modalBody = "Hate to be the one to break it to you, but your wallet is not cleared for this mint. Please check in our Discord Channel on how to get it sorted.";
          this.showModal();
          break;
        case 411:
          // wallet sanctioned...
          this.modalTitle = "We've got bad news..."
          this.modalBody = "Your wallet didn't pass security controls and is not allowed to compelte this transaction. Please consider using another wallet.";
          this.showModal();
          break;
        default:
          // other errors
          this.modalTitle = "Whoops! Something, somewhere, somehow didn't work."
          this.modalBody = "We're not sure what happened. Please try again to see if that fixes the issue.";
          this.showModal();
          break;
      }
      this.showSpinner = false;
    }
   

  }

  private showModal() {
    this.displayModal = true;
  }

  private hideModal() {
    this.displayModal = false;
  }

  public modalDismissed() {
    this.hideModal(); // just in case
    // callback from modal after ok, etc
    switch (this.modalCode) {
      case 411:
      case 403:
        this.router.navigate(['../changewallet'], { relativeTo: this.route });
        break;
      case 409:
        this.router.navigate(['../claimed'], { relativeTo: this.route });
        break;
      case 410:
        this.router.navigate(['..'], { relativeTo: this.route });
        break;
      default:
        this.router.navigate(['..'], { relativeTo: this.route });
        break;
    }
  }
  public modalMobileDismissed() {
    this.showSpinner = true;
    this.hideModal();
    // callback from modal after ok, etc
    this.isAlert = true;
    this.isNoAlert = false;
    this.showSpinner = false;
  }

}
