import { Component, OnInit } from '@angular/core';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { Dashboard } from '../../models/dashboard/dashboard';
import { Observable } from 'rxjs'

@Component({
  selector: 'app-dashboard-display',
  templateUrl: './dashboard-display.component.html',
  styleUrls: ['./dashboard-display.component.scss']
})
export class DashboardDisplayComponent implements OnInit {
  public dashboard?: Dashboard;
  loaded = false;
  constructor(
    private dataSessionService: DataSessionService
  )
  { }

  ngOnInit(): void {
    // get initial value and listen for changes
    this.dashboard = this.dataSessionService.dashboardGet();
    this.dataSessionService.dashboardObserve()
    .subscribe(dashboard => {
      this.dashboard = dashboard;
    });
  }

}
