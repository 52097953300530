import { Injectable } from '@angular/core';
import { Dashboard } from 'src/app/components/models/dashboard/dashboard';
import { BehaviorSubject, Observable, map } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DataSessionService {
  private dashboardSubject: BehaviorSubject<Dashboard | undefined>;
  private walletSubject: BehaviorSubject<string | undefined>;
  private changeWallet: boolean;
  private isMobile: BehaviorSubject<boolean>;
  private protensAmount: BehaviorSubject<number>;

  constructor() {
    this.dashboardSubject = new BehaviorSubject<Dashboard | undefined>(undefined);
    this.walletSubject = new BehaviorSubject<string | undefined>(undefined);
    this.changeWallet = false;
    this.protensAmount = new BehaviorSubject<number>(0);
    this.isMobile = new BehaviorSubject<boolean>(false);
  }

  public dashboardSet(dashboard: Dashboard | null): void {
    if (dashboard) {
      this.dashboardSubject.next(dashboard);
    }
  }

  public dashboardGet(): Dashboard | undefined {
    return this.dashboardSubject.getValue();
  }

  public dashboardObserve(): Observable<Dashboard | undefined> {
    return this.dashboardSubject.asObservable();
  }

  public walletSet(wallet?: string): void {
    this.walletSubject.next(wallet);
  }

  public walletGet(): string | undefined {
    return this.walletSubject.getValue();
  }

  public walletObserve(): Observable<string | undefined> {
    return this.walletSubject.asObservable();
  }

  public eventName(): string {
    return this.dashboardSubject.getValue()?.event?.name ?? "";
  }

  public eventId(): string {
    return this.dashboardSubject.getValue()?.event?.uniqueId ?? "";
  }

  public clearDataSession(): void {
    this.dashboardSubject = new BehaviorSubject<Dashboard | undefined>(undefined);
    this.walletSubject = new BehaviorSubject<string | undefined>(undefined);
  }

  public changeWalletSet(shouldChange: boolean) {
    this.changeWallet = shouldChange;
  }

  public changeWalletGet(): boolean {
    return this.changeWallet;
  }

  public isMobileGet(): boolean {
    return this.isMobile.getValue();
  }

  public isMobileSet(isM: boolean) {
    this.isMobile.next(isM);
  }

  public isMobileObserve(): Observable<boolean> {
    return this.isMobile.asObservable();
  }

  public protensAmountObserve(): Observable<number> {
    return this.protensAmount.asObservable();
  }

  public protensAmountSet(amount: number) {
    return this.protensAmount.next(amount);
  }
}
