import { Component, OnInit } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProtensService } from 'src/app/services/overview/protens.service';
import { CombineService } from 'src/app/services/combine/combine.service';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { AuthService } from 'src/app/components/auth/service/auth.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  public isLogged: boolean = false;
  public isMobileUA: boolean = false;
  public isMobile: boolean = false;
  public showSpinner: boolean = false;
  public protens?: number;
  public hasWallet: boolean = false;
  
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private protensService: ProtensService,
    private router: Router,
    private combineService: CombineService,
    private dataSessionService: DataSessionService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isLogged = await this.authService.isLoggedIn();
    this.isMobile = this.dataSessionService.isMobileGet();

    let userWallets = await (await this.combineService.getUserWallets()).body;
    if (userWallets && userWallets?.address) {

      if(userWallets.address.length > 0){
        this.dataSessionService.walletSet(userWallets?.address?.[0]);
        this.hasWallet = true;
      }else{
        this.dataSessionService.walletSet(undefined);
        this.hasWallet = false;
      }
      
    }

    let _protens = await firstValueFrom(this.protensService.getProtens());
    console.log("get user protens = " + _protens)
    this.dataSessionService.protensAmountSet(_protens?.amount);

    this.dataSessionService.protensAmountObserve().subscribe(data => {
      this.protens = data;
    });
  }


}
