import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EthereumChainsService {

  constructor() { }

  polygonMainnet(): number {
    return 137;
  }

  polygonMainnetChainId(): string {
    return '0x' + this.toHexString(this.polygonMainnet());
  }

  polygonMumbai(): number {
    return 80001;
  }

  polygonMumbaiChainId(): string {
    return '0x' + this.toHexString(this.polygonMumbai());
  }

  toHexString(num: number): string {
    return num.toString(16);
  }

  isPolygonMainnet(chainId: string): boolean {
    return (chainId != null && typeof chainId !== 'undefined' && chainId === this.toHexString(this.polygonMainnet()));
  }

  isPolygonMumbai(chainId: string): boolean {
    return (chainId != null && typeof chainId !== 'undefined' && chainId === this.toHexString(this.polygonMumbai()));
  }
}
