<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <div class="event-title">{{dashboard?.event?.name}}</div>
    <div class="two-col-wrapper">
        <div class="left-col-content">
            <div class="dramatic-header">Claim Your Unioverse Collectible</div>
            <div class="information">Get this Unioverse Collectible NFT for free. This numbered collectible will only be
                available for a limited time and never again.</div>
            <app-count-down></app-count-down>
            <button class="button" (click)="userChangeWalletAction()">Change Wallet</button>
            <button class="button_margin" (click)="navigateToClaim()">Back To Claim</button>
            <div class="content-wrap">
                <div>
                    <div class="highlight_button">
                        <span><img src="assets/images/unioverse-icon-transparent.svg" alt="Unioverse"
                                class="login_alignment">
                            <p class="login_axis">Log In</p>
                        </span>
                    </div>
                </div>
                <span class="before">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                <div>
                    <div class="highlight_button">
                        <i class="pi pi-wallet icon1"></i>
                        <span class="icon_content">Collect</span>
                    </div>
                </div>
                <span class="before">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                <div>
                    <div class="highlight_button">
                        <i class="pi pi-check-circle icon1"></i>
                        <span class="icon_content">Claim</span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-dashboard-display></app-dashboard-display>
        </div>
    </div>
</div>
<!-- ##Ended Desktop View## -->
<!-- ##Started Mobile View## -->
<div class="mobile-view">
    <div class="event-title">{{dashboard?.event?.name}}</div>
    <div class="two-col-wrapper">
        <div class="left-col-content">
            <div class="dramatic-header">Unioverse Collectibles</div>
            <div>
                <app-count-down></app-count-down>
            </div>
            <div>
                <app-dashboard-display></app-dashboard-display>
            </div>
            <button class="button" (click)="userChangeWalletAction()">Claim</button>
            <div class="information">Get this Unioverse Collectible NFT for free. This numbered collectible will only be
                available for a limited time and never again.</div>



        </div>
    </div>
</div>
<!-- ##Ended Mobile View## -->

<!-- <div>
    <p>Selected wallet: {{selectedWallet}}</p>
</div>

<div>
    <button (click)="userChangeWalletAction()">Need to change your wallet?</button>
</div>
 -->