import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CountdownTimerService } from 'src/app/services/countdown-timer/countdown-timer.service';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';
import { AuthService } from '../../auth/service/auth.service';
import { Dashboard } from '../../models/dashboard/dashboard';
declare const gtag: Function;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  public isLogged: boolean = false;
  public dashboard?: Dashboard;
  public isMobileUA: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataSessionService: DataSessionService,
    private tradeUpService: TradeupService,
    private countdownTimerService: CountdownTimerService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-HZCK5676PL', { 'event_login': event.urlAfterRedirects });
      }
    })
  }

  async ngOnInit(): Promise<void> {
    try {
      // have to (un)box resolver data to get our proper class
      this.dashboard = this.dataSessionService.dashboardGet();
      this.isMobileUA = this.dataSessionService.isMobileGet();
      // subscribe to changes in dashboard
      this.dataSessionService.dashboardObserve()
        .subscribe(newDashboard => {
          this.dashboard = newDashboard;
        });
      // check if event is valid
      if (!this.isEventValid()) {
        // not valid - navigate to 'event'
        this.router.navigate(['/']);
        return;
      }
      // check if event is expired
      if (this.isEventExpired()) {
        // expired, go to expired page
        this.router.navigate(['expired'], { relativeTo: this.activatedRoute });
        return;
      }
      // check if we are logged in
      this.isLogged = await this.authService.isLoggedIn();
      if (this.isLogged) {
        //check to see if we have already claimed the event
        if (await this.hasUserClaimed()) {
          // we have, navigate to claimed page
          this.router.navigate(['claimed'], { relativeTo: this.activatedRoute });
          return;
        }

        // if we are logged in and we have not claimed the event, skip to the 'connect' page
        this.router.navigate(['connect'], { relativeTo: this.activatedRoute });
        return;
      }
    } catch (ex) {
      console.error(`in catch: ${ex}`);
      console.dir(ex);
    }
  }

  /**
   * Check that the current event has not expired
   * @returns T/F
   */
  private isEventExpired(): boolean {
    const now = new Date();
    const utcMillliseconds = now.getTime();
    const utcSeconds = Math.round(utcMillliseconds / 1000);
    let eventEndTime = this.dashboard?.event?.endTimeInSec;
    return this.isEventValid() && (eventEndTime ?? 0) < utcSeconds;
    //return false;
  }

  /**
   * Check whether the event given is valid: dashboard exists, event exists, event has started (expired not checked).
   * @returns T/F if the event is valid
   */
  private isEventValid(): boolean {
    const now = new Date();
    const utcMillliseconds = now.getTime();
    const utcSeconds = Math.round(utcMillliseconds / 1000);
    let eventTime = this.dashboard?.event?.startTimeInSec;

    return this.dashboard !== null &&
      typeof this.dashboard !== 'undefined' &&
      this.dashboard.isValid() &&
      (eventTime ?? 0) < utcSeconds;
  }

  /**
   * Get if the user has claimed the event
   * @returns Promise of T/F
   */
  private async hasUserClaimed(): Promise<boolean> {
    try {
      let response = await this.tradeUpService.findClaim(this.dashboard?.event?.uniqueId);
      return Promise.resolve(response.ok);
    } catch (err: any) {
      // assuming this is an HttpError
      return Promise.resolve(false);
    }
  }

  /**
   * Initiate Keycloak authentication
   */
  async login() {
    this.showSpinner = true;
    await this.authService.login();
    this.showSpinner = false;

  }
}
