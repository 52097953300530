import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './components/auth/auth.guard';
import { ChangeWalletComponent } from './components/pages/change-wallet/change-wallet.component';
import { ClaimComponent } from './components/pages/claim/claim.component';
import { ConnectComponent } from './components/pages/connect/connect.component';
import { EventComponent } from './components/pages/event/event.component';
import { ExpiredEventComponent } from './components/pages/expired-event/expired-event.component';
import { HasClaimedComponent } from './components/pages/has-claimed/has-claimed.component';
import { InstallmetamaskComponent } from './components/pages/install-metamask/install-metamask.component';
import { InvalidEventComponent } from './components/pages/invalid-event/invalid-event.component';
import { DashboardResolver } from './components/resolvers/dashboard/dashboard.resolver';
import { FindClaimResolver } from './components/resolvers/find-claim/find-claim.resolver';
import { UaParseResolver } from './components/resolvers/ua-parse/ua-parse.resolver';
import { DownloadComponent } from './components/synced/shared/pages/download/download.component';

const routes: Routes = [
  {
    path: '***',
    component: InvalidEventComponent,
    resolve: { isMobile: UaParseResolver }
  },
  {
    path: 'install-metamask',
    pathMatch: 'full',
    component: InstallmetamaskComponent,
    data: {}
  },

  {
    path: 'dev',
    children: [
      {
        path: 'event',
        children: [
          {
            path: ':id',
            children: [
              {
                path: '',
                component: EventComponent,
                data: {},
                resolve: { dashboard: DashboardResolver }
              },
              {
                path: 'expired',
                component: ExpiredEventComponent,
                data: {},
                resolve: { dashboard: DashboardResolver }
              },
              {
                path: 'connect',
                component: ConnectComponent,
                data: { roles: ['USER'] },
                resolve: { dashboard: DashboardResolver }
              },
              {
                path: 'claim',
                component: ClaimComponent,
                data: { roles: ['USER'] },
                resolve: { dashboard: DashboardResolver }
              },
              {
                path: 'claimed',
                component: HasClaimedComponent,
                data: { roles: ['USER'] },
                resolve: { dashboard: DashboardResolver }
              },
              {
                path: 'changewallet',
                component: ChangeWalletComponent,
                data: { roles: ['USER'] },
                resolve: { dashboard: DashboardResolver }
              },
            ]
          }
        ]
      }
    ]
  },

  {
    path: 'event',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InvalidEventComponent,
        resolve: {isMobile: UaParseResolver}
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: EventComponent,
            data: {},
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
          {
            path: 'expired',
            component: ExpiredEventComponent,
            data: {},
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
          {
            path: 'connect',
            canActivate: [AuthGuard],
            component: ConnectComponent,
            data: { roles: ['USER'] },
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
          {
            path: 'claim',
            canActivate: [AuthGuard],
            component: ClaimComponent,
            data: { roles: ['USER'] },
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
          {
            path: 'claimed',
            canActivate: [AuthGuard],
            component: HasClaimedComponent,
            data: { roles: ['USER'] },
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
          {
            path: 'changewallet',
            canActivate: [AuthGuard],
            component: ChangeWalletComponent,
            data: { roles: ['USER'] },
            resolve: { dashboard: DashboardResolver, isMobile: UaParseResolver }
          },
        ]
      }
    ]
  },
  {
    path: 'download',
    canActivate: [AuthGuard],
    component: DownloadComponent,
    data: { roles: ['USER'] },
    resolve: { isMobile: UaParseResolver }
  },
  {
    path: '**',
    pathMatch: 'full',
    component: InvalidEventComponent,
    data: {},
    resolve: {isMobile: UaParseResolver}
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot((routes), { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}