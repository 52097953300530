import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathUtilsService {

  constructor() { }

  public genRandHex(size: number): string {
    return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
  }

  public addMinutesToDate(minutes: number, date: Date): Date {
    return new Date(date.getTime() + (minutes * 60000));
  }
}
