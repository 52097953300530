<div class="layout-main-container">
    <div class="left-col-content">
        <div class="dramatic-header center_align">What is Metamask?</div>
        <div class="explain">Metamask is a digital wallet that allows you to hold and transfer cryptocurrencies and
            NFTs. <br/>You need Metamask in order to receive your Unioverse Collectible.</div>
        <div class="center_align">
            <button class="install">
                <a href="https://metamask.io/download/" target="_blank" class="install_text">Get Metamask</a>
            </button>
        </div>
        <div class="center_align">
            <button class="install " (click)="onInstalledClick()">
                <span class="install_text">Metamask is installed</span>
                <!-- <a href="https://metamask.io/download/" target="_blank" class="install_text">Metamask is installed</a>-->
            </button>
        </div>
    </div>
</div>