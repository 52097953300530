<div class="layout-wrapper">
  <div class="content-wrap">
    <app-app_topbar></app-app_topbar>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>

</div>