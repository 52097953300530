import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { MathUtilsService } from 'src/app/services/math-utils/math-utils.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TradeupWeb3Service } from 'src/app/services/tradeup-web3/tradeup-web3.service';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';
import { Dashboard } from '../../models/dashboard/dashboard';

declare let window: any;

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {
  public addressSelected?: string;
  public dashboard: Dashboard | null;
  public isMobileUA: boolean = false;
  public showSpinner: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataSessionService: DataSessionService,
    private mathUtilsService: MathUtilsService,
    private tradeupWeb3Service: TradeupWeb3Service,
    private toastService: ToastService,
    private tradeUpService: TradeupService
  ) {
    this.dashboard = null;
  }

  async ngOnInit(): Promise<void> {
    let dashboard = this.dataSessionService.dashboardGet();
    this.isMobileUA = this.dataSessionService.isMobileGet();

    if (!dashboard?.isValid()) {
      // go back to 'start'
      this.router.navigate(['../'], { relativeTo: this.route });
      return;
    }

    // do we have a wallet for the user?
    try {
      const userWallets = await this.tradeUpService.getUserWallets();
      if (userWallets.body && userWallets.body.address) {
        if (userWallets.body.address.length > 0) {
          this.dataSessionService.walletSet(userWallets.body.address[userWallets.body.address.length - 1]);
          this.router.navigate(['../claim'], { relativeTo: this.route });
          return;
        }
      }
    } catch (ex) { } // ignore catch

    // connect to metamask
    if (!this.tradeupWeb3Service.isMetamaskInstalled() && !this.dataSessionService.isMobileGet()) {
      // metamask is not part of window
      this.router.navigate(['install-metamask']);
      return;
    } else if (!this.dataSessionService.isMobileGet()) {
      // init metamask
      this.tradeupWeb3Service.initMetamaskProvider();
    }
  }

  async connectMetamask(): Promise<void> {
    try {
      this.showSpinner = true;
      await this.tradeupWeb3Service.requestWalletAndSign();
      // should now have wallet and stored signature, continue to claim
      this.router.navigate(['../claim'], { relativeTo: this.route });
      this.showSpinner = false;
    } catch (err: any) {
      console.error(`Received error requesting wallet and signing: ${err}`);
    }
  }

  async getWalletID(walletInput: string): Promise<void> {
    // validate wallet
    if (typeof walletInput === 'undefined' ||
      walletInput == null ||
      walletInput.length != 42 ||
      !walletInput.startsWith('0x') ||
      walletInput.match(/^0x[0-9a-fA-F]+$/) == null ||
      walletInput.match(/^0x[0-9a-fA-F]+$/)?.length != 1) {
        // failed validation
        window.alert("Your wallet input is not valid, please check your input and try again. Wallets must begin with '0x' and be alpha-numeric only.");
    } else {
      // save the wallet to pass up with our claim
      this.dataSessionService.walletSet(walletInput);
      this.router.navigate(['../claim'], { relativeTo: this.route });
    }
  }
}
