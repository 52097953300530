import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected override router:Router,
    protected override keycloakAngular: KeycloakService) {
      super(router, keycloakAngular);
    }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      /*
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url
      });
      */
     this.router.navigate(['event', route.paramMap.get('id')]);
     return false;
    }

    const requiredRoles = route.data["roles"];

    if (requiredRoles instanceof Array && requiredRoles.length > 0) {
      return requiredRoles.every((role) => this.roles.includes(role));
    }

    // no roles assigned to route
    return true;
  }
}
