import { NftEvent } from "../nft-event/nft-event";
import { NftToken } from "../nft-token/nft-token";

export class Dashboard {
    public event?: NftEvent;
    public token?: NftToken;
    public timestamp?: Date;

    constructor(jsonStr: string | undefined | null) {
        if (jsonStr) {
            try {
                let obj = JSON.parse(jsonStr);
                this.event = obj['event'] ?? undefined;
                this.token = obj['token'] ?? undefined;
                if (obj['timestamp']) {
                    this.timestamp = new Date(obj['timestamp']);
                }
                if (this.event && this.event.start && this.event.start.charAt(this.event.start.length-1) !== 'Z') {
                    this.event.start = this.event.start.concat('Z');
                }
                if (this.event && this.event.end && this.event.end.charAt(this.event.end?.length-1) !== 'Z') {
                    this.event.end = this.event.end.concat('Z');
                }
            } catch (err) {
                this.event = undefined;
                this.token = undefined;
            }
        }

        if (typeof this.timestamp === 'undefined')
            this.timestamp = new Date();
        
        Object.setPrototypeOf(this, Dashboard.prototype);
    }

    public isValid(): boolean {
        return typeof this.event !== 'undefined' &&
        typeof this.token !== 'undefined' &&
        typeof this.event.end !== 'undefined' &&
        typeof this.event.start !== 'undefined' && 
        typeof this.event.name !== 'undefined' &&
        typeof this.event.uniqueId !== 'undefined' &&
        typeof this.event.startTimeInSec !== 'undefined' &&
        typeof this.event.endTimeInSec !== 'undefined' &&
        typeof this.token.name !== 'undefined' &&
        typeof this.token.uniqueId !== 'undefined' &&
        typeof this.token.image !== 'undefined' &&
        typeof this.timestamp !== 'undefined' &&
        Math.floor((Math.abs((new Date()).getTime() - this.timestamp.getTime()) / 1000)) < 30; // refresh every 30s
    }
}
