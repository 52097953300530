import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './components/auth/service/auth.service';
import { Dashboard } from './components/models/dashboard/dashboard';
import { DataSessionService } from './services/data-session/data-session.service';
import { TradeupService } from './services/tradeup/tradeup.service';
import { Message, MessageService } from 'primeng/api';

declare const window: any;

@Component({
    selector: 'app-app_topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']

})
export class AppTopBarComponent implements OnInit {

    public dashboard?: Dashboard;
    public isWalletValid: boolean = false;
    public isLogged: boolean = false;
    public walletId?: string;
    public selectedId?: string;
    public selectedWallet?: string;
    public showSpinner: boolean = false;
    public isMobileUA: boolean = false;
    msgs: any[] = [];
    constructor(
        private dataSession: DataSessionService,
        private tradeUpService: TradeupService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private messageService: MessageService,
    ) { }

    async ngOnInit() {
        // set whether we are logged in
        this.dashboard = this.dataSession.dashboardGet();
        this.isLogged = await this.authService.isLoggedIn();
        this.selectedWallet = this.dataSession.walletGet();
        this.dataSession.walletObserve().subscribe(walletId => {
            // convert wallet to 0x####...####
            this.walletId = `${walletId?.substring(0, 6)}...${walletId?.substring(walletId.length - 4, walletId.length)}`;
            this.isWalletValid = typeof walletId !== 'undefined';
            this.selectedWallet = walletId;
        });
        this.isMobileUA = this.dataSession.isMobileGet();
        this.dataSession.isMobileObserve().subscribe(isMobile => {
            this.isMobileUA = isMobile;
            console.log('topbar is mobiel now: ' + isMobile);
        });
    }

    async logout() {
        // set we are logged out the session
        this.showSpinner = true;
        await this.authService.logout();
        //this.router.navigate(['../event'], { relativeTo: this.route });
        this.showSpinner = false;
    }

    async changeWallet() {
        // ignore if we are on mobile
        if (this.isMobileUA)
            return;

        this.showSpinner = true;
        this.msgs = [];
        this.dashboard = await this.dataSession.dashboardGet();
        if (this.dashboard == null || this.dashboard == undefined ||
            this.selectedWallet == null || this.selectedWallet == undefined) {
            this.isMobileUA = false;
        } else {
            this.router.navigate(['event', this.dashboard?.event?.uniqueId, 'changewallet']);
        }
        this.showSpinner = false;
    }
}

