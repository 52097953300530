import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './app.footer.component';
import { AppTopBarComponent } from './app.topbar.component';
//import { AppTopbarComponent } from './components/synced/shared/app_topbar/app_topbar.component';
import { AuthModule } from './components/auth/auth.module';
import { CountDownComponent } from './components/common/count-down/count-down.component';
import { DashboardDisplayComponent } from './components/common/dashboard-display/dashboard-display.component';
import { GlobalErrorHandler } from './components/exceptions/global-handler/global-handler';
import { ChangeWalletComponent } from './components/pages/change-wallet/change-wallet.component';
import { ClaimComponent } from './components/pages/claim/claim.component';
import { ConnectComponent } from './components/pages/connect/connect.component';
import { EventComponent } from './components/pages/event/event.component';
import { ExpiredEventComponent } from './components/pages/expired-event/expired-event.component';
import { HasClaimedComponent } from './components/pages/has-claimed/has-claimed.component';
import { InstallmetamaskComponent } from './components/pages/install-metamask/install-metamask.component';
import { InvalidEventComponent } from './components/pages/invalid-event/invalid-event.component';
import { DashboardResolver } from './components/resolvers/dashboard/dashboard.resolver';
import { FindClaimResolver } from './components/resolvers/find-claim/find-claim.resolver';
import { UaParseResolver } from './components/resolvers/ua-parse/ua-parse.resolver';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PlatformModule } from '@angular/cdk/platform';
import {  NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppTopBarComponent ,
    EventComponent,
    ExpiredEventComponent,
    ClaimComponent,
    HasClaimedComponent,
    ConnectComponent,
    InstallmetamaskComponent,
    CountDownComponent,
    DashboardDisplayComponent,
    InvalidEventComponent,
    ChangeWalletComponent,

  ],

  imports: [
    PlatformModule,
    BrowserModule,
    CommonModule,
    ProgressSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    ToastModule,
    DialogModule,
    BrowserAnimationsModule,
    ButtonModule,
    TooltipModule,
    NgxGoogleAnalyticsModule.forRoot('G-HZCK5676PL'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    DashboardResolver,
    FindClaimResolver,
    UaParseResolver,
    MessageService,
    //{provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})}
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
