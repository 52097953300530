import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { AuthService } from '../../auth/service/auth.service';
declare const gtag: Function;
@Component({
  selector: 'app-invalid-event',
  templateUrl: './invalid-event.component.html',
  styleUrls: ['./invalid-event.component.scss']
})
export class InvalidEventComponent implements OnInit {
  public isLogged: boolean = false;
  public isMobileUA: boolean = false;
  public showSpinner: boolean = false;
  
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dataSessionService: DataSessionService
  ) {
  }

  async ngOnInit(): Promise<void> {
    // check if we are logged in
    this.isLogged = await this.authService.isLoggedIn();
    this.isMobileUA = this.dataSessionService.isMobileGet();
  }

  async login() {
    this.showSpinner = true;
    await this.authService.login();
    this.router.navigate(['../event'], { relativeTo: this.route });
    this.showSpinner = false;
  }

}
