import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
/*
Sentry.init({
  dsn: 'https://240b2a23522241a6a5f565d3d2d36b1c@o4503922643173376.ingest.sentry.io/4503922644353024',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', 'https://tradeup-dev.unioverse.com'],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 1.0
});
*/
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
