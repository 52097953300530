import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserWallets } from 'src/app/components/models/user/user-wallets';

@Injectable({
  providedIn: 'root'
})
export class CombineService {

  constructor(
    private httpClient: HttpClient
  ) { }

  putUserWalletChange(body: any) : Promise<HttpResponse<any>> {
    const url = `${environment.tradeUpUrl}/user/wallet/change`;
    return lastValueFrom(this.httpClient.put(url, body, {observe: 'response', responseType: 'json'}));
  }

  getUserWallets() : Promise<HttpResponse<UserWallets>> {
    const url = `${environment.tradeUpUrl}/user/wallets`;
    return lastValueFrom(this.httpClient.get(url, {observe: 'response', responseType: 'json'}));
  }
}