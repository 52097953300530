import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { StringUtilsService } from 'src/app/services/string-utils/string-utils.service';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';
import { Dashboard } from '../../models/dashboard/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolver implements Resolve<any | Dashboard> {

  constructor(
    private sanitizer: DomSanitizer,
    private tradeUpService: TradeupService,
    private stringUtilService: StringUtilsService,
    private dataSessionService: DataSessionService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    // use stored dashboard if it is valid
    let requestedEvent = route.paramMap.get('id') ?? '';
    let cachedDashboard = this.cachedDashboard();

    if (typeof cachedDashboard.isValid != 'function' && cachedDashboard.isValid() && cachedDashboard.event?.uniqueId != requestedEvent) {
      // set our cached dashboard and exit
      this.dataSessionService.dashboardSet(cachedDashboard);
      return;
    }

    // check for 'dev' path and create a faux dashboard if so
    const isDevDashboard = route.pathFromRoot.reduce<boolean>((memo, path) => {
      return memo || path.routeConfig?.path === 'dev';
    }, false);

    if (!isDevDashboard) {
      // normal logic to fetch the dashboard from the server
      //Sentry.captureMessage("Requesting dashboard from server");
      try {
        let dashboardResponse = await this.tradeUpService.getDashboard(requestedEvent);
        this.cachedDashboardSet((dashboardResponse.body) ? new Dashboard(JSON.stringify(dashboardResponse.body)) : new Dashboard(null));
        console.dir(this.cachedDashboard());
        //Sentry.captureMessage(`received message: ${JSON.stringify(dashboardResponse)}`);
        this.dataSessionService.dashboardSet(this.cachedDashboard());
      } catch (ex) {
        this.cachedDashboardDelete();
      }
    } else {
      // logic to write a faux dashboard
      const fauxDashboard = new Dashboard(JSON.stringify(
        {
          event: {
            id: 0,
            start: this.stringUtilService.dateString(new Date()),
            end: this.stringUtilService.dateString(new Date(Date.now() + 61 * 60000)),
            uniqueId: route.paramMap.get('id') ?? 'devevent',
            name: 'Development Event'
          },
          token: {
            id: 0,
            //image_url: 'https://d2ttpy97tp32jl.cloudfront.net/nft-assets/nft-images/NftPreview_Avatar.png',
            image: this.sanitizer.bypassSecurityTrustResourceUrl('https://d2ttpy97tp32jl.cloudfront.net/nft-assets/nft-images/NftPreview_Avatar.png'),
            name: 'Dev Avatar',
            description: 'Avatar for viewing the html/css of a page without fetching real resources',
            uniqueId: 'dev_avatar',
            tier: 0,
            set: 1,
            number: 1,
          }
        }
      ));
      this.cachedDashboardSet(fauxDashboard);
      this.dataSessionService.dashboardSet(fauxDashboard);
    }
  }

  private cachedDashboard(): Dashboard {
    return new Dashboard(localStorage.getItem("cache_dashboard") ?? "{}");
  }

  private cachedDashboardSet(dashboard: Dashboard): void {
    localStorage.setItem("cache_dashboard", JSON.stringify(dashboard));
  }

  private cachedDashboardDelete(): void {
    localStorage.removeItem("cache_dashboard");
  }
}
