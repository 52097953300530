import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from 'src/app/components/shared/toast-type';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private messageService: MessageService
  ) { }

  /**
   * Display a toast on the website as a 'success'
   * @param title Short summary/name for toast (title)
   * @param detail Longer description for toast (body)
   * @param ttl How long the toast will display (in ms)
   */
  displaySuccess(title: string, detail: string, ttl: number) {
      this.messageService.add({
        severity: ToastType.SUCCESS,
        summary: title,
        detail,
        life: ttl
      });
  }

  /**
   * Display a toast on the website as a 'info'
   * @param title Short summary/name for toast (title)
   * @param detail Longer description for toast (body)
   * @param ttl How long the toast will display (in ms)
   */
  displayInfo(title: string, detail: string, ttl: number) {
    this.messageService.add({
      severity: ToastType.INFO,
      summary: title,
      detail,
      life: ttl
    });
  }

  /**
   * Display a toast on the website as a 'warn'
   * @param title Short summary/name for toast (title)
   * @param detail Longer description for toast (body)
   * @param ttl How long the toast will display (in ms)
   */
  displayWarn(title: string, detail: string, ttl: number) {
    this.messageService.add({
      severity: ToastType.WARN,
      summary: title,
      detail,
      life: ttl
    });
  }

  /**
   * Display a toast on the website as a 'error'
   * @param title Short summary/name for toast (title)
   * @param detail Longer description for toast (body)
   * @param ttl How long the toast will display (in ms)
   */
  displayError(title: string, detail: string, ttl: number) {
    this.messageService.add({
      severity: ToastType.ERROR,
      summary: title,
      detail,
      life: ttl
    });
  }
}
