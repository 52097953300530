import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { TradeupWeb3Service } from 'src/app/services/tradeup-web3/tradeup-web3.service';
import { Dashboard } from '../../models/dashboard/dashboard';

@Component({
  selector: 'app-change-wallet',
  templateUrl: './change-wallet.component.html',
  styleUrls: ['./change-wallet.component.scss']
})
export class ChangeWalletComponent implements OnInit {
  public dashboard?: Dashboard;
  public selectedWallet?: string;
  public isWalletValid: boolean = false;
  public isMobileUA: boolean = false;
  constructor(
    private dataSession: DataSessionService,
    private tradeupWeb3Service: TradeupWeb3Service,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isMobileUA = this.dataSession.isMobileGet();
  }

  async userChangeWalletAction(): Promise<void> {
    await this.tradeupWeb3Service.requestWalletAndSign();
    this.selectedWallet = this.dataSession.walletGet();
    console.log('selected wallet: ' + this.selectedWallet);
  }

  navigateToClaim() {
    this.router.navigate(['../claim'], { relativeTo: this.route });
  }
}
