import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private keycloakService: KeycloakService) { }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      return this.keycloakService.getKeycloakInstance().idTokenParsed;
    } catch (ex) {
      console.error("Exception retrieving logged user", ex);
      return undefined;
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(forceReload = false): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile(forceReload);
  }

  public login(): Promise<void> {
    return this.keycloakService.login();
  }

  public logout(): Promise<void> {
    return this.keycloakService.logout();
  }

  public redirectToProfile(): Promise<void> {
    return this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }
}
