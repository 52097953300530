import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';
import { Dashboard } from '../../models/dashboard/dashboard';

@Component({
  selector: 'app-expired-event',
  templateUrl: './expired-event.component.html',
  styleUrls: ['./expired-event.component.scss']
})
export class ExpiredEventComponent implements OnInit {
  private dashboard?: Dashboard;
  public openseaLink?: String;
  public isMobileUA: boolean = false;

  constructor(
    private dataSessionService: DataSessionService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dashboard = this.dataSessionService.dashboardGet();
    this.isMobileUA = this.dataSessionService.isMobileGet();

    if (!this.dashboard?.isValid()) {
      // go back to 'start'
      this.router.navigate(['../'], { relativeTo: this.route });
      return;
    }

    this.openseaLink = `https://opensea.io/collection/unioverse-collectibles?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=ID&search[stringTraits][0][values][0]=${this.dashboard.token?.set}%3A${this.dashboard.token?.tier}%3A${this.dashboard.token?.number}`
  }

}
