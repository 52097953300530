import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataSessionService } from 'src/app/services/data-session/data-session.service';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class UaParseResolver implements Resolve<boolean> {
  constructor(
    private dataSessionService: DataSessionService
  )
  {}
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.dataSessionService.isMobileSet(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/g.test(window.navigator.userAgent));
    return of(false);
  }
}
