export const environment = {
  production: true,
  keycloak: {
      // TODO: Change issuer to use sub-domain of website host
      // TODO: point to production keycloak when we have that setup
      issuer: 'https://keycloaks.unioverse.com/auth/',
      realm: 'unio-realm',
      clientId: 'unio-app'
  },
  tradeUpUrl: 'https://unft-tradeup.rgc-external-stage.unioverse.com',
  overviewUrl: 'https://unft-tradeup-overview.rgc-external-stage.unioverse.com'
};
