<div class="layout-topbar">
    <div class="layout-topbar-logo">
        <img src="assets/images/unioverse-white-text-only.svg" alt="Unioverse" class="image_logo">
    </div>
    <!-- <div class="layout-messagebox">
        <div class="message-box" style="z-index: 999;">
            <p-messages styleClass="msg" severity="info" [(value)]="msgs" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"></p-messages>
        </div>
    </div> -->
    <div #topbarmenu class="layout-topbar-menu">
        <nav>
            <ul class="header-icons" id="menubar">
                <li class="wallet"><button [disabled]="!isLogged && !isMobileUA" [ngClass]="!(isLogged && isMobileUA) ? 'notLogged' : 'logged'"
                        (click)="changeWallet()"><i class="pi pi-wallet"></i></button></li>
                <li class="signout_gap">
                    <div *ngIf="isWalletValid">Wallet ID</div>
                    <div *ngIf="isWalletValid">{{walletId}}</div>
                </li>
                <li *ngIf="isLogged" class="sign-out" (click)="logout()"><i class="pi pi-sign-out"></i></li>
            </ul>
        </nav>
     </div>
     

    <p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
</div>