import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TradeupService } from 'src/app/services/tradeup/tradeup.service';

@Injectable({
  providedIn: 'root'
})
export class FindClaimResolver implements Resolve<boolean> {
  constructor(
    private tradeUpService: TradeupService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // check for 'dev' path and return 'false' if so
    const isDevDashboard = route.pathFromRoot.reduce<boolean>((memo, path) => {
      return memo || path.routeConfig?.path === 'dev';
    }, false);

    if (!isDevDashboard) {
      return this.tradeUpService.findClaim(route.paramMap.get('id') ?? '').then((response) => {
        return response.ok;
      });
    } else {
      return Promise.resolve(false);
    }
  }
}
