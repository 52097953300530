import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataSessionService } from '../data-session/data-session.service';
import { EthereumChainsService } from '../ethereum-chains/ethereum-chains.service';
import { MathUtilsService } from '../math-utils/math-utils.service';
import { ToastService } from '../toast/toast.service';
import { NonceResponse } from '../tradeup/models/nonce-response/nonce-response';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class TradeupWeb3Service {
  private provider?: Web3Provider;
  private signer?: JsonRpcSigner;
  private useTestNet: boolean;
  private selectedWallet?: string;
  private walletSignature?: string;

  constructor(
    private httpClient: HttpClient,
    private mathUtilsService: MathUtilsService,
    private ethereumChainsService: EthereumChainsService,
    private toastService: ToastService,
    private dataSessionService: DataSessionService
  ) {
    this.useTestNet = !environment.production;
  }

  //#region Accessors
  public walletSignatureGet(): string | undefined {
    return this.walletSignature;
  }
  //#endregion

  //#region Server Requests

  /**
   * Using a wallet obtained via Web3, get a nonce generated on the server.
   * @param wallet Wallet obtained from Web3 (Metamask)
   * @returns Connection response that should contain a string of a nonce
   */
  async requestNonce(wallet: string | undefined): Promise<HttpResponse<NonceResponse>> {
    const url = `${environment.tradeUpUrl}/wallet/nonce`;
    return lastValueFrom(this.httpClient.post<NonceResponse>(url, { walletId: wallet }, { observe: 'response', responseType: 'json' }));
  }

  /**
   * Saves our signed message onto the server.
   * @param sign 
   * @returns 
   */
  async requestSignStore(sign: string | undefined, wallet: string | undefined): Promise<HttpResponse<any>> {
    if (false) { // TODO: Enable this once the endpoint is live
      const url = `${environment.tradeUpUrl}/wallet/sign`;
      return lastValueFrom(this.httpClient.post(url, { sign, wallet }, { observe: 'response', responseType: 'json' }));
    } else {
      return lastValueFrom(of(new HttpResponse<any>({})));
    }
  }

  //#endregion

  //#region Metamask

  /**
   * Attempt to connect to Metamask in the users browser. If Metamask is not installed, this will return false.
   */
  async requestWalletAndSign(): Promise<void> {
    try {
      if (!this.provider) {
        this.initMetamaskProvider();
      }

      try {
        // likely they have not connected any wallet
        await this.provider?.send('wallet_requestPermissions', [{ eth_accounts: {} }]); // currently only permission and required to get wallets
        await this.provider?.send('eth_requestAccounts', []);

        // get wall and ask to sign it
        this.selectedWallet = await this.signer?.getAddress();
        if (!this.selectedWallet) throw new Error('Did not receive wallet');
      } catch (err: any) {
        console.error(`Error getting wallet: ${err}`);
        console.dir(err);
        if (err.code == -32002) {
          // metamask action pending
          this.toastService.displayError(
            'Metamask Action Pending',
            'There is already a Metamask action pending, please click on the Metamask extension in your browser to complete the action.',
            5000
          );
        } else {
          // other error, likely user cancel
          this.toastService.displayError(
            'Metamask Action Required',
            'We need your wallet to ensure correct ownership and that you receive your free NFT!',
            5000
          );
        }
        throw err;
      }

      try {
        // prompt to sign the wallet
        const nonceRequest = await this.requestNonce(this.selectedWallet);
        const nonce = nonceRequest.body?.nonce ?? this.mathUtilsService.genRandHex(32);

        this.walletSignature = await this.signMessage(this.selectedWallet, nonce, `To mint and fetch your collection, please confirm you are the owner of this wallet. Random nonce : `);
        console.log(`Wallet signature: ${this.walletSignature}`);
        if (!this.walletSignature) throw new Error('Signature failed.');
      } catch (err: any) {
        console.error(`Error signing the wallet: ${err}`);
        this.toastService.displayError(
          'Metamask Action Required',
          'We need to sign your wallet to ensure correct ownership and that you receive your free NFT!',
          5000
        );
        throw err;
      }

      try {
        // store our signature on the server
        await this.requestSignStore(this.walletSignature, this.selectedWallet);
        this.dataSessionService.walletSet(this.selectedWallet);
      } catch (err: any) {
        console.error(`Error saving signature: ${err}`);
        this.toastService.displayError(
          `Error Registering Wallet`,
          `We ran into an error registering your wallet, please wait a few minutes and try again.`,
          5000
        );
        throw err;
      }
    } catch (err: any) {
      console.error(`Error attempting to connect to metamask: ${err.message}`);
      throw err;
    }
  }

  /**
   * Return currently selected address from Mmask.
   * @returns 
   */
  async getSelectedAddress(): Promise<string | undefined> {
    return await this.signer?.getAddress();
  }

  /**
   * Check whether window element has reference to metamask.
   * @returns T/F
   */
  isMetamaskInstalled(): boolean {
    return !(window == null || window.ethereum == null || typeof window.ethereum === 'undefined');
  }

  /**
   * Initialize Metamask.
   */
  initMetamaskProvider(): void {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    this.signer = this.provider.getSigner();
  }

  /**
   * Get the currently allowed wallets from provider.
   * @returns 
   */
  async listAccounts(): Promise<Array<string> | undefined> {
    return this.provider?.listAccounts();
  }

  /**
   * 
   * @param wallet 
   * @param nonce 
   * @param message 
   * @returns 
   */
  async signMessage(wallet: string | undefined, nonce: any, message: string | undefined): Promise<string | undefined> {
    let finalMessage = Array.of(message, (typeof nonce != 'undefined') ? nonce.toString() : '').join('');
    //let finalMessage = Array.of(message, '\n', 'wallet: ', (typeof wallet !== 'undefined') ? wallet : '', '\n', 'nonce: ', (typeof nonce !== 'undefined') ? nonce.toString() : '').join('');
    let signResult: string | undefined = await this.signer?.signMessage(finalMessage);
    console.log(`signResult: ${signResult}`);
    return signResult;
  }

  //#endregion

  //#region Ethereum Chains

  /**
   * Add either Polygon Mainnet or Mumbai to Metmask
   * @returns 
   */
  async addPolygonToMMask(): Promise<boolean> {
    if (this.useTestNet) {
      return this.addPolygonMumbaiToMmask();
    }

    return this.addPolygonMainnetToMmask();
  }

  /**
   * Add the Polygon Mumbai Testnet to the user's Metamask (if is not already present).
   * @returns T/F
   */
  private async addPolygonMumbaiToMmask(): Promise<boolean> {
    try {
      console.log('add call');
      const chainId = this.ethereumChainsService.polygonMumbaiChainId();
      console.log(chainId);
      const chainDef = {
        chainId: chainId.toString(),
        rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
        chainName: 'Mumbai',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
        },
        blockExplorerUrls: ['https://mumbai.polygonscan.com/']
      };
      let response = await this.provider?.send('wallet_addEthereumChain', [chainDef]);
      console.log(`Chain add response`);
      console.dir(response);
      return response;
    } catch (err) {
      return false;
    }
  }

  /**
   * Add the Polygon Mainnet to the user's Metamask (if is not already present).
   * @returns T/F
   */
  private async addPolygonMainnetToMmask(): Promise<boolean> {
    try {
      const chainId = this.ethereumChainsService.polygonMainnetChainId();
      const chainDef = {
        chainId,
        rpcUrls: ['https://polygon-rpc.com', 'https://rpc.ankr.com/polygon'],
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decmials: 18
        },
        blockExplorerUrls: ['https://polygonscan.com/']
      };
      let response = await this.provider?.send('wallet_addEthereumChain', [chainDef]);
      console.log(`Add polygon mainnet response`);
      console.dir(response);
      return response;
    } catch (err) {
      return false;
    }
  }

  /**
   * Check our current chain set in metamask, if it is not on the polygon mainnet then ask to switch
   * @returns T/F if we switched to the polygon mainnet
   */
  async checkAndSwitchToPolygonChain(): Promise<number> {
    if (this.useTestNet) {
      return this.checkAndSwitchToPolygonMumbaiChain();
    }

    return this.checkAndSwitchToPolygonMainnetChain();
  }

  /**
   * Ask to switch to Mumbai Testnet
   * @returns 
   */
  private async checkAndSwitchToPolygonMumbaiChain(): Promise<number> {
    try {
      let response = await this.provider?.send('wallet_switchEthereumChain', [{ chainId: this.ethereumChainsService.polygonMumbaiChainId() }]);
      console.log(`Mumbai switch:`);
      console.dir(response);
      return 0;
    } catch (err: any) {
      console.error(err);
      return err.code ?? 10;
    }
  }

  /**
   * Ask to switch to Polygon Mainnet
   * @returns 
   */
  private async checkAndSwitchToPolygonMainnetChain(): Promise<number> {
    try {
      let response = await this.provider?.send('wallet_switchEthereumChain', [{ chainId: this.ethereumChainsService.polygonMainnetChainId() }]);
      console.log(`Mainnet switch`);
      console.dir(response);
      return 0;
    } catch (err: any) {
      console.error(err);
      return err.code ?? 10;
    }
  }

  /**
   * Get the current chain that Metmask is on.
   * @returns string indicating which chain we are currently on
   */
  async getCurrentChain(): Promise<string> {
    return await this.provider?.send('eth_chainId', []);
  }

  /**
   * Verify that our current chain is a polygon chain
   * @param chainId 
   * @returns 
   */
  isChainPolygon(chainId: string): boolean {
    return (this.useTestNet && this.ethereumChainsService.isPolygonMumbai(chainId)) || this.ethereumChainsService.isPolygonMainnet(chainId);
  }

  //#endregion

}
