import { ErrorHandler, Injectable, Injector } from "@angular/core"
import { ToastService } from "src/app/services/toast/toast.service";
import { ActionableError } from "../base-error/base-error";
import { ServerError } from "../server-error/server-error";
import { ToastableError } from "../toast-error/toast-error";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector
    ) {}

    private instanceOfToastableError(obj : any) : obj is ToastableError {
        return 'toastAction' in obj;
    }

    private instanceOfActionableError(obj : any) : obj is ActionableError {
        return 'action' in obj;
    }

    private instanceOfError(obj : any) : obj is Error {
        return 'message' in obj;
    }

    private instanceOfServerError(obj : any) : obj is ServerError {
        return 'printError' in obj;
    }

    handleError(error: any): void {
        // need to get some services
        let toastService = this.injector.get(ToastService);
        // need to unbox promise exceptions:
        if (error.promise && error.rejection) {
            error = error.rejection;
        }
        console.dir(error);
        // handle error
        if (this.instanceOfToastableError(error) && toastService != null) {
            error.toastAction(toastService);
        } else if (this.instanceOfActionableError(error)) {
            error.action();
        } else if (this.instanceOfServerError(error)) {
            console.error(error.printError());
        } else if (this.instanceOfError(error)) {
            // default handling
            console.error(`Generic Error:\nException in operation ${error.message}`);
            console.dir(error);
            if (typeof error.stack !== 'undefined') {
                console.dir(error.stack);
            }
            throw error;
        } else {
            console.error(`Unknown error object: ${error}`);
            console.dir(error);
            throw error;
        }
    }
}
