import { Injectable } from '@angular/core';
import { Timer } from './models/timer';
import { interval, map, scan, takeWhile, Observable } from 'rxjs'
import { DataSessionService } from '../data-session/data-session.service';
import { Dashboard } from 'src/app/components/models/dashboard/dashboard';

@Injectable({
  providedIn: 'root'
})
export class CountdownTimerService {
  private countDown$: Observable<Timer>;
  private dashboard$: Observable<Dashboard | undefined>;

  constructor(
    private dataSessionService: DataSessionService
  ) {
    this.countDown$ = new Observable<Timer>();
    this.dashboard$ = this.dataSessionService.dashboardObserve();
    this.dashboard$.subscribe(dashboard => {
      // create a new timer with new dashboard interval
      //let initialSeconds = Math.floor((Date.parse(dashboard?.event?.end ?? "") - Date.now()) / 1000);
      // change to use new form (no parsing)
      let now = new Date();
      let initialSeconds = Math.max(Math.floor((dashboard?.event?.endTimeInSec ?? 0) - Math.floor(now.getTime() / 1000)), 0);
      this.countDown$ = this.createCountdown(initialSeconds);
    });
  }

  public getCountdown(): Observable<Timer> {
    return this.countDown$;
  }

  private createCountdown(startValue: number, timerInterval?: number): Observable<Timer> {
    if (!timerInterval) timerInterval = 1000;

    return interval(timerInterval).
    pipe(
      map(() => -1),
      scan((acc: number, curr: number) => acc + curr, startValue),
      takeWhile(val => val > 0)
    )
    .pipe(
      map(val => {
        let hour = Math.floor(val / 3600);
        let min = Math.floor(val / 60) % 60;
        let sec = val % 60;
        return {hours: `${(hour >= 10) ? hour : `0${hour}`}`, minutes: `${(min >= 10) ? min : `0${min}`}`, seconds: `${(sec >= 10) ? sec : `0${sec}`}`};
      })
    );
  }
}
