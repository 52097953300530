<div id="layout-control">
    <div id="pagetitle" class="dramatic-header">Almost There!</div>
    <div id="message">
        <div class="information" *ngIf="!isMobileUA">Before you can claim your free Unioverse collectible NFT, you need to connect your Metamask wallet.</div>
        <div class="information" *ngIf="isMobileUA">Please enter your wallet above.<br>If you don't have a wallet yet, you can set one up and connect with Metamask on a desktop.</div>
    </div>
    <div id="timer">
        <app-count-down></app-count-down>
    </div>
    <div id="actionbutton">
        <div *ngIf="isMobileUA">
            <form>
                <input type="text" id="walletID" placeholder="0xfff...." #walletID>
            </form>
            <button class="button" (click)="getWalletID(walletID.value)">Connect Your Wallet</button>
        </div>
        <div *ngIf="!isMobileUA">
            <button class="button_connect" (click)="connectMetamask()"><img src="assets/images/metamask.svg" alt="Unioverse" id="main-logo" />Connect Your Wallet</button>
        </div>
    </div>
    <div id="progress-icons">
        <div class="content-wrap">
            <div>
                <div class="highlight_button">
                    <span><img src="assets/images/unioverse-icon-transparent.svg" alt="Unioverse"
                            class="login_alignment">
                        <p class="login_axis">Log In</p>
                    </span>
                </div>
            </div>
            <span class="before">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
            <div>
                <div class="highlight_button">
                    <i class="pi pi-wallet icon1"></i>
                    <span class="icon_content">Collect</span>

                </div>
            </div>
            <span class="before_off">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
            <div>
                <div class="highlight_button">
                    <i class="pi pi-check-circle icon1_off"></i>
                    <span class="icon_content_off">Claim</span>
                </div>
            </div>
        </div>
    </div>
    <div id="nftart">
        <app-dashboard-display></app-dashboard-display>
    </div>
</div>
<p-progressSpinner *ngIf="showSpinner" class="show-spinner"></p-progressSpinner>
