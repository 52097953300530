import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, retry, ObservableInput, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtenLog } from './models/proten-log/proten-log';
import { protensResponse as ProtensResponse, UserWallets } from './protens_model';
@Injectable({
  providedIn: 'root'
})
export class ProtensService {
  readonly httpResponseStringOpts: Object = {
    observe: 'response',
    responseType: 'text'
  };

  constructor(protected httpClient: HttpClient) {

   }

  getProtens() : Observable<ProtensResponse> {
    const url = `${environment.overviewUrl}/user/protens`;
    return this.httpClient.get<ProtensResponse>(url).pipe(retry({count: 2, delay: this.shouldRetry}));
  }

  claimProtens(data: any) : Observable<ProtenLog | undefined> {
    const url = `${environment.overviewUrl}/user/protens/claim`;
    return this.httpClient.put<ProtenLog>(url, data)
  }
  getUserWallets() : Promise<HttpResponse<UserWallets>> {
    const url = `${environment.overviewUrl}/user/wallets`;
    return lastValueFrom(this.httpClient.get(url, {observe: 'response', responseType: 'json'}));
  }
  putUserWalletChange(body: any) : Promise<HttpResponse<any>> {
    const url = `${environment.overviewUrl}/user/wallet/change`;
    return lastValueFrom(this.httpClient.put(url, body, {observe: 'response', responseType: 'json'}));
  }

  shouldRetry(error: HttpErrorResponse, retryCount: number) : ObservableInput<any> {
    if (error.status > 499 || error.status < 400) {
      return timer(5000*retryCount);
    }

    throw error;
  }
}
