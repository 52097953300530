<div class="py-16 bg-black site-footer">
    <div class="container">
        <div class="row">
            <div class="w-20 h-20 mx-auto text-white logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 410.9 410.9" fill="currentColor"
                    class="foot_bar_logo">
                    <path d="M264.1,141.1c-2.5,0-4.5,2.2-4.5,5V232c0,16.9-6.9,20.6-23.4,20.6h-61.5c-16.7,0-23.4-3.7-23.4-20.6V146
                                c0-2.7-2-5-4.5-5h-24.2c-2.5,0-4.5,2.2-4.5,5v85c0,29.2,17,47.5,56.7,47.5h61.5c39.7,0,56.7-18.3,56.7-47.5v-85c0-2.7-2-5-4.5-5
                                H264.1z"></path>
                    <path
                        d="M61.3,216C61.3,128.2,132.5,57,220.3,57c37.3,0,71.5,12.8,98.6,34.3C289.9,61.1,249.2,42.3,204,42.3
                                c-87.8,0-158.9,71.2-158.9,158.9c0,50.5,23.6,95.5,60.3,124.6C78.1,297.3,61.3,258.6,61.3,216z">
                    </path>
                </svg>
            </div>
            <ul
                class="flex items-center gap-x-4 gap-y-1 mb-3 flex-wrap justify-center border-t-blueGray-700 sm:border-0 sm:pt-0" style="text-align:center;">
                <li class="text-blueGray-500">
                    <a class="text-white  menu-item menu-item-type-custom menu-item-object-custom menu-item-134"
                        href="https://random.games/careers.php" target="_blank">Careers</a>
                </li>
                <li class="text-blueGray-500">
                    <a class="text-white  menu-item menu-item-type-custom menu-item-object-custom menu-item-317"
                        href="https://random.games/press.php" target="_blank">Press</a>
                </li>
                <li class="text-blueGray-500">
                    <a class="text-white  menu-item menu-item-type-custom menu-item-object-custom menu-item-123"
                        href="https://random.games/about.php" target="_blank">Corporate</a>
                </li>
                <li class="text-blueGray-500">
                    <a class="text-white  menu-item menu-item-type-custom menu-item-object-custom menu-item-371"
                        href="https://random.games/terms-of-service.php" target="_blank">Terms of Service</a>
                </li>
                <!--<li class="text-blueGray-500">
                    <a class="text-white iubenda-nostyle iubenda-noiframe iubenda-embed menu-item menu-item-type-custom menu-item-object-custom menu-item-365"
                        href="https://www.iubenda.com/privacy-policy/72034589" target="_blank">Privacy
                        Policy</a>
                    <script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
                </li>
                <li class="text-blueGray-500">
                    <a class=""
                        href="https://www.iubenda.com/privacy-policy/72034589/cookie-policy" target="_blank">Cookie
                        Policy</a>
                    <script type="text/javascript" src="https://cdn.iubenda.com/iubenda_i_badge.js"></script>
                </li>-->
                <li class="text-blueGray-500">
                    <a href="https://www.iubenda.com/privacy-policy/72034589" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe text-white iubenda-nostyle iubenda-noiframe iubenda-embed menu-item menu-item-type-custom menu-item-object-custom menu-item-365" title="Privacy Policy ">Privacy Policy</a>
                    <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
                </li>
                <li class="text-blueGray-500">
                    <a href="https://www.iubenda.com/privacy-policy/72034589/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe text-white iubenda-nostyle iubenda-noiframe iubenda-embed menu-item menu-item-type-custom menu-item-object-custom menu-item-366" title="Cookie Policy ">Cookie Policy</a>
                    <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
                </li>
            </ul>
            <ul class="socialicons">
                <li><a href="https://discord.gg/theunioverse"><svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg></a></li>
                <li><a href="https://twitter.com/theunioverse"><svg height="100%" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"><path d="M161.014,464.013c193.208,0 298.885,-160.071 298.885,-298.885c0,-4.546 0,-9.072 -0.307,-13.578c20.558,-14.871 38.305,-33.282 52.408,-54.374c-19.171,8.495 -39.51,14.065 -60.334,16.527c21.924,-13.124 38.343,-33.782 46.182,-58.102c-20.619,12.235 -43.18,20.859 -66.703,25.498c-19.862,-21.121 -47.602,-33.112 -76.593,-33.112c-57.682,0 -105.145,47.464 -105.145,105.144c0,8.002 0.914,15.979 2.722,23.773c-84.418,-4.231 -163.18,-44.161 -216.494,-109.752c-27.724,47.726 -13.379,109.576 32.522,140.226c-16.715,-0.495 -33.071,-5.005 -47.677,-13.148l0,1.331c0.014,49.814 35.447,93.111 84.275,102.974c-15.464,4.217 -31.693,4.833 -47.431,1.802c13.727,42.685 53.311,72.108 98.14,72.95c-37.19,29.227 -83.157,45.103 -130.458,45.056c-8.358,-0.016 -16.708,-0.522 -25.006,-1.516c48.034,30.825 103.94,47.18 161.014,47.104" style="fill-rule:nonzero;"></path></svg></a></li>
                <li><a href="https://www.youtube.com/@theunioverse"><svg height="100%" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"><path d="M501.303,132.765c-5.887,-22.03 -23.235,-39.377 -45.265,-45.265c-39.932,-10.7 -200.038,-10.7 -200.038,-10.7c0,0 -160.107,0 -200.039,10.7c-22.026,5.888 -39.377,23.235 -45.264,45.265c-10.697,39.928 -10.697,123.238 -10.697,123.238c0,0 0,83.308 10.697,123.232c5.887,22.03 23.238,39.382 45.264,45.269c39.932,10.696 200.039,10.696 200.039,10.696c0,0 160.106,0 200.038,-10.696c22.03,-5.887 39.378,-23.239 45.265,-45.269c10.696,-39.924 10.696,-123.232 10.696,-123.232c0,0 0,-83.31 -10.696,-123.238Zm-296.506,200.039l0,-153.603l133.019,76.802l-133.019,76.801Z" style="fill-rule:nonzero;"></path></svg></a></li>
            </ul>
            <p class="text-sm text-center text-blueGray-400">&copy; 2022-2023 Random Games Inc. All rights reserved.</p>
        </div>
    </div>
</div>