<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <div class="two-col-wrapper">
        <div>
            <app-dashboard-display></app-dashboard-display>
        </div>
        <div class="left-col-content center_align">
            <h1 class="dramatic-header">CONGRATULATIONS!</h1>
            <p class="information">Your Unioverse Collectible will be airdropped after the claim event ends, and may take several hours to reach your connected wallet. You can see your Collectible at: <a href="https://opensea.io/account">opensea.io/account</a>.</p>
                <div class="callout">
                    <p class="information" style="margin-top: 0px; font-size: 2rem;"><strong>Play the Proving Grounds Now!</strong></p>
                    <p class="information"><a href="https://cdn1.unioverse.com/UnioverseViewer/WindowsUpdater/UnioverseViewerSetup0.2.62_signed.exe" class="protens-button">DOWNLOAD PROVING GROUNDS</a></p>
                    <p class="information">Check wallet for your Reyu Clone and play our alpha!</p>
                    <p class="information">Even better, play the Proving Grounds and earn Protens, which can be used to unlock Unioverse digital rewards!</p>
                </div>
            <!--<p class="information">Be among the first to hear about our next collectible drop!</p>
            <div class="callout">Make sure to check your <a href="https://protens.unioverse.com/">Protens Count!</a></div>
            <div class="information">Join our Discord and follow us on Twitter.</div>-->
            <p></p>
            <app-count-down></app-count-down>
            <div class="social_media center_align">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
    </div>
</div>
<!-- ##Ended Desktop View## -->
<!-- ##Started Mobile View## -->
<div class="mobile-view">
    <div class="two-col-wrapper">
        <div>
            <h1 class="dramatic-header center_align">CONGRATULATIONS!</h1>
            <app-dashboard-display></app-dashboard-display>
        </div>
        <div class="left-col-content center_align">
            <p class="information">Your Unioverse Collectible will be airdropped after the claim event ends, and may take several hours to reach your connected wallet. You can see your Collectible at: <a href="https://opensea.io/account">opensea.io/account</a>.</p>
                <div class="callout" style="margin-bottom: 10px;">
                    <p class="information" style="margin-top: 0px; font-size: 2rem;"><strong>Play the Proving Grounds Now!</strong></p>
                    <p class="information"><a href="https://cdn1.unioverse.com/UnioverseViewer/WindowsUpdater/UnioverseViewerSetup0.2.62_signed.exe" class="protens-button">DOWNLOAD PROVING GROUNDS</a></p>
                    <p class="information">Check wallet for your Reyu Clone and play our alpha!</p>
                    <p class="information">Even better, play the Proving Grounds and earn Protens, which can be used to unlock Unioverse digital rewards!</p>
                </div>
            <!--<p class="information">Be among the first to hear about our next collectible drop!</p>
            <div class="callout">Make sure to check your <a href="https://protens.unioverse.com/">Protens Count!</a></div>
            <div class="information">Join our Discord and follow us on Twitter.</div>-->
            <app-count-down></app-count-down>
            <div class="social_media_invalid">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
            </div>
            <div class="social_media_invalid">
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
    </div>
</div>
<!-- ##Ended Mobile View## -->