<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <main>
        <!--New User Before April 26-->
        <!--<div class="reyu-clone-layout">
            <div><img class="reyu-image" src="./assets/images/reyu-clone.png" alt="Reyu"></div>
            <div class="txt-col">
                <h1>Get Ready for Reyu</h1>
                <p>Welcome to your Unioverse account! Your Reyu Hero will be in your Unioverse Account on April 26th! Come back then to install and play the Proving Grounds alpha.</p>
                <p>Want to mint our next Hero, Krishah?<br><a style="color:white;" href="https://protens.unioverse.com/collect">Start earning Protens!</a></p>
                <p class="sub-req">Windows PC Only</p>
            </div>
        </div>-->

        <!--New User During Promo-->
        <!--<div class="reyu-clone-layout">
            <div><img class="reyu-image" src="./assets/images/reyu-clone.png" alt="Reyu"></div>
            <div class="txt-col">
                <h1>Ready to Play?</h1>
                <p>Welcome to your Unioverse account! Your Reyu is being airdropped to your connected wallet.</p>
                <p>Download the Proving Grounds alpha right now to start playing!</p>
                <a href="https://cdn1.unioverse.com/UnioverseViewer/WindowsUpdater/UnioverseViewerSetup0.2.62_signed.exe" class="download-button">Download</a>
                <p class="sub-req">Windows PC Only</p>
                <p class="specs-title">Mininum System Specs</p>
                <ul class="specs-list">
                    <li>CPU: Intel Core i5-7400 or AMD equivalent</li>
                    <li>RAM: 8 GB</li>
                    <li>GPU: NVIDIA GeForge GTX 1060 Ti or AMD equivalent</li>
                    <li>OS: Windows 10 64-bit or newer</li>
                </ul>
                <p class="specs-title">Recommended System Specs</p>
                <ul class="specs-list">
                   <li>CPU: Intel Core i5-7400 or AMD equivalent</li>
                   <li>RAM: 16 GB</li>
                   <li>GPU: NVIDIA GeForge GTX 2070 or AMD equivalent</li>
                   <li>OS: Windows 10 64-bit or newer</li>
                </ul>
            </div>
        </div>-->

        <!--New User After April 26 Promo Over-->
        <!--Add Danger Room video-->
        <div class="reyu-clone-layout">
            <div><img class="reyu-image" src="./assets/images/reyu-clone.png" alt="Reyu"></div>
            <div class="txt-col">
                <h1>Ready to Play?</h1>
                <p>Welcome to the Unioverse! If you want to start playing our proving grounds alpha:</p>
                <p>Buy a <a href="https://opensea.io/category/unioverse" style="color: white;">Unioverse Hero off OpenSea</a> and download Proving Grounds alpha (PC only).</p>
                <p>Or, amass <a style="color:white;" href="https://www.unioverse.com/explore/quickstart-how-to-begin-with-unioverse-collectibles/">Unioverse Collectibles</a> and earn enough Protens to get our next Hero, Krishah, minting in May.</p>
                <p>Then, download the Proving Grounds Alpha for PC:</p>
                <a href="https://cdn1.unioverse.com/UnioverseViewer/WindowsUpdater/UnioverseViewerSetup0.2.62_signed.exe" class="download-button">Download (PC only)</a>
                <br>
                <p class="sub-req">Mininum System Specs</p>
                <ul class="specs-list">
                    <li>CPU: Intel Core i5-7400 or AMD equivalent</li>
                    <li>RAM: 8 GB</li>
                    <li>GPU: NVIDIA GeForge GTX 1060 Ti or AMD equivalent</li>
                    <li>OS: Windows 10 64-bit or newer</li>
                    <li>Direct3D 12 compatability</li>
                </ul>
                <p class="sub-req">Recommended System Specs</p>
                <ul class="specs-list">
                   <li>CPU: Intel Core i5-7400 or AMD equivalent</li>
                   <li>RAM: 16 GB</li>
                   <li>GPU: NVIDIA GeForge GTX 2070 or AMD equivalent</li>
                   <li>OS: Windows 10 64-bit or newer</li>
                   <li>Direct3D 12 compatability</li>
                </ul>
            </div>
        </div>

        <!--Past-->
        <!--<div class="reyu-clone-layout">
            <div><img class="reyu-image" src="./assets/images/reyu-clone.png" alt="Reyu"></div>
            <div class="txt-col">
                <h1>Ready to Play?</h1>
                <p>Thanks for creating an account! If you want to start playing our Proving Grounds alpha: 
                <p>Buy a Unioverse Hero off OpenSea and download Proving Grounds alpha (PC Only)</p>
                <p style="text-align: center; margin-left: -30px;"><strong><em>--OR--</em></strong></p>
                <p>Amass Unioverse Collectibles and earn enough Protens to get our next Hero, Krishah, minting in May.</p>
                <a href="#" class="download-button">Download</a>
                <p class="sub-req">Windows PC Only</p>
            </div>
        </div>-->

        <!--old-->
        <!--<div id="warp">
            <div>
                <h1>Combine Unioverse Collectibles:</h1>
                <div class="description">
                    <p>If you own Unioverse Collectibles, this is where you can see your collection and Forge them into higher-tier Collectibles. To get started, click the Login button below.</p>
                </div>
                <div class="description">
                    <p>Not collecting Unioverse Collectibles yet? You should! <a href="https://unioverse.com/news/how-to-mint-the-reyu-genesis-hero-nft/">Check out this FAQ</a> to begin.</p>
                </div>
            </div>
        </div>-->
    </main>
</div>
<!-- ##Ended Desktop View## -->