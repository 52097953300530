<!-- ##Started Desktop View## -->
<div class="desktop-view">
    <div class="two-col-wrapper">
        <div class="left-col-content">
            <app-dashboard-display></app-dashboard-display>
            <a href="{{openseaLink}}" target="_blank" class="onOpenSea">View copies for sale on Open Sea</a>
        </div>
        <div>
            <h1 class="dramatic-header right_align">This Collectible is No Longer Available</h1>
            <p class="information">Each Unioverse Collectible NFT is only available for a limited time. Be among the
                first to know when our next free Unioverse Collectible drops by following us on Twitter or joining our
                Discord.</p>
            <div class="social_media">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
    </div>
</div>
<!-- ##Ended Desktop View## -->
<!-- ##Started Mobile View## -->
<div class="mobile-view">
    <div class="two-col-wrapper">
        <div>
            <h1 class="dramatic-header right_align">This Collectible is No Longer Available</h1>
            <p class="information">Each Unioverse Collectible NFT is only available for a limited time. Be among the
                first to know when our next free Unioverse Collectible drops by following us on Twitter or joining our
                Discord.</p>
                <app-dashboard-display></app-dashboard-display>
                <a href="{{openseaLink}}" target="_blank" class="onOpenSea">View copies for sale on Open Sea</a><!--copies one-->
            <div class="social_media_expired">
                <a href="https://discord.gg/theunioverse" target="_blank" class="social_media_links"><button
                        class="theunioverse_fb"><img class="twitter_style_fb fbsize"
                            src="./assets/images/icon-discord.svg" />&#x00A0;#theunioverse</button></a>
            </div>
            <div class="social_media_expired">
                <a href="https://twitter.com/theunioverse" target="_blank" class="social_media_links"> <button
                        class="theunioverse_rec"><img class="twitter_style fbsize"
                            src="./assets/images/icon-twitter.svg" />&#x00A0;@theunioverse</button></a>
            </div>
        </div>
        <!--<div class="left-col-content">
        </div>-->
    </div>
</div>
<!-- ##Ended Mobile View## -->