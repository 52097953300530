export class StringsEn {
    // moving hardcoded strings here to make it easier to reference and change as needed
    public static CLAIM_TOKEN = {
        SUCCESS_TITLE: 'Claim Success',
        SUCCESS_BODY: 'Your NFT was claimed successfully!',
        FAILED_VERIFICATION_TITLE: 'Wallet Failed Verification',
        FAILED_VERIFICATION_BODY: 'Your wallet was not verifiable, please select another wallet.',
        FAILED_OTHER_TITLE: 'Claim Failed',
        FAILED_OTHER_BODY: 'Unable to claim your NFT. Site will reload, please try again.'
    }
}
